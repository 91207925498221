<template>



      <div class="relative w-55vw mx-auto py-6 sm:py-10 sm:px-0 max-h-full">
          <!-- Modal content -->
          <div class="relative bg-clrAccentLight rounded-3xl shadow dark:bg-gray-700">

              <!-- Modal header -->
              <div class="flex items-start justify-between p-4 md:p-5 rounded-t">
                  <h3 class="text-h2 font-semibold leading-none">
                      Чтобы начать <span class="font-normal italic">расскажите:</span>
                  </h3>
                  <button type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="form-modal">
                      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                      </svg>
                      <span class="sr-only">Close modal</span>
                  </button>
              </div>

              <!-- Modal body -->
              <div class="p-4 md:p-6">

                  <div v-if="isNotFormSubmited">
                      <form @submit.prevent="submitForm">
                          <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 text-clrMainDark mb-12"> 
                              <div>
                                  <label for="name" class="block mb-2 text-note font-normal text-clrSecondaryDark">Ваше имя</label>
                                  <input required v-model="form.name" id="name" type="text" class="block w-full py-2.5 px-0 border-0 border-b-1.5 border-clrMainDark text-clrMainDark font-medium focus:text-clrMainDark text-main focus:outline-none focus:ring-0 focus:border-b-1.5 focus:border-clrMainDark" placeholder="Имя"  />
                                  <p v-if="errors.name" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ errors.name[0] }}</p>  
                              </div>
                          

                              <div>
                                  <label for="phone_number" class="block mb-2 text-note font-normal text-clrSecondaryDark">Номер телефона</label>
                                  <input required v-model="form.phone_number" id="phone_number" @input="formatPhoneNumber" type="tel" class="block w-full py-2.5 px-0 border-0 border-b-1.5 border-clrMainDark text-clrMainDark font-medium focus:text-clrMainDark text-main focus:outline-none focus:ring-0 focus:border-b-1.5 focus:border-clrMainDark" placeholder="+375 (29) 123-45-67"/>
                                  <p v-if="errors.phone_number" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ errors.phone_number[0] }}</p>
                              </div>
                          </div>
              
              
                          <div class="flex flex-col sm:flex-row gap-x-6 gap-y-8 mb-12"> 
                              <div>
                                  <div>
                                      <label for="contact_by_phone" class="block mb-2 text-note font-normal text-clrSecondaryDark">Позвонить</label>
                                      <label class="relative inline-flex items-center cursor-pointer">
                                          <input v-model="form.contact_by_phone" class="sr-only peer" type="checkbox">
                                          <div class="h-10 sm:h-12 w-fit sm:w-28 peer before:rounded-full before:content-['Позвонить'] before:absolute before:h-10 sm:before:h-12 before:w-22 sm:before:w-28 before:flex before:justify-center before:items-center before:text-main before:text-clrMainDark before:font-medium before:transition-transform before:duration-300 before:ease-in-out before:border-1.5 before:border-clrMainDark before:peer-focus:ring-0 after:rounded-full after:content-['Telegram'] after:absolute after:h-10 sm:after:h-12 after:w-22 sm:after:w-28 after:text-main after:bg-clrMainDark after:flex after:justify-center after:items-center after:text-clrMainLight after:font-normal after:transition-transform after:duration-300 after:ease-in-out after:scale-0 peer-checked:after:bg-black peer-checked:after:content-['Позвонить'] peer-checked:after:transform peer-checked:after:scale-100"></div>
                                      </label>
                                      <p v-if="errors.contact_by_phone" class="mt-2 text-sm text-red-600 dark:text-red-500">{{ errors.contact_by_phone[0] }}</p>
                                  </div>
                              </div>
              
                              <!-- <label class="relative inline-flex items-center cursor-pointer">
                              <input class="sr-only peer" type="checkbox">
                              <div class="peer before:rounded-full before:content-['Telegram'] before:absolute before:h-12 before:w-24 before:top-1 before:left-1 before:flex before:justify-center before:items-center before:text-sky-800 before:font-bold before:transition-transform before:duration-300 before:ease-in-out before:bg-white before:border-4 before:peer-focus:ring-4 after:rounded-full after:content-['Telegram'] after:absolute after:h-12 after:w-24 after:bg-white after:top-1 after:left-1 after:flex after:justify-center after:items-center after:text-sky-800 after:font-bold after:transition-transform after:duration-300 after:ease-in-out after:scale-0 peer-checked:after:bg-black peer-checked:after:content-['Telegram'] peer-checked:after:transform peer-checked:after:scale-100"></div>
                              </label> -->
                          


                              <div>
                                  <label for="contact_by_messenger" class="block mb-2 text-note font-normal text-clrSecondaryDark">Через мессенджер</label>            
                                  <div id="contact_by_messenger" class="flex flex-row flex-wrap gap-x-1">

                                      <div class="">
                                          <label class="relative inline-flex items-center cursor-pointer">
                                              <input v-model="form.contact_by_telegram" class="sr-only peer" type="checkbox">
                                              <div class="h-10 sm:h-12 w-22 sm:w-28 peer before:rounded-full before:content-['Telegram'] before:absolute before:h-10 sm:before:h-12 before:w-22 sm:before:w-28 before:flex before:justify-center before:items-center before:text-main before:text-clrMainDark before:font-medium before:transition-transform before:duration-300 before:ease-in-out before:border-1.5 before:border-clrMainDark before:peer-focus:ring-0 after:rounded-full after:content-['Telegram'] after:absolute after:h-10 sm:after:h-12 after:w-22 sm:after:w-28 after:text-main after:bg-clrMainDark after:flex after:justify-center after:items-center after:text-clrMainLight after:font-normal after:transition-transform after:duration-300 after:ease-in-out after:scale-0 peer-checked:after:bg-black peer-checked:after:content-['Telegram'] peer-checked:after:transform peer-checked:after:scale-100"></div>
                                          </label>
                                      </div>

                                      <div class="">
                                          <label class="relative inline-flex items-center cursor-pointer">
                                              <input v-model="form.contact_by_whatsapp" class="sr-only peer" type="checkbox">
                                              <div class="h-10 sm:h-12 w-22 sm:w-28 before:rounded-full before:content-['WhatsApp'] before:absolute before:h-10 sm:before:h-12 before:w-22 sm:before:w-28 before:flex before:justify-center before:items-center before:text-main before:text-clrMainDark before:font-medium before:transition-transform before:duration-300 before:ease-in-out before:border-1.5 before:border-clrMainDark before:peer-focus:ring-0 after:rounded-full after:content-['WhatsApp'] after:absolute after:h-10 sm:after:h-12 after:w-22 sm:after:w-28 after:text-main after:bg-clrMainDark after:flex after:justify-center after:items-center after:text-clrMainLight after:font-normal after:transition-transform after:duration-300 after:ease-in-out after:scale-0 peer-checked:after:bg-black peer-checked:after:content-['WhatsApp'] peer-checked:after:transform peer-checked:after:scale-100"></div>
                                          </label>
                                      </div>

                                      <div class="">
                                          <label class="relative inline-flex items-center cursor-pointer">
                                              <input v-model="form.contact_by_viber" class="sr-only peer" type="checkbox">
                                              <div class="h-10 sm:h-12 w-16 sm:w-28 peer before:rounded-full before:content-['Viber'] before:absolute before:h-10 sm:before:h-12 before:w-16 sm:before:w-28 before:flex before:justify-center before:items-center before:text-main before:text-clrMainDark before:font-medium before:transition-transform before:duration-300 before:ease-in-out before:border-1.5 before:border-clrMainDark before:peer-focus:ring-0 after:rounded-full after:content-['Viber'] after:absolute after:h-10 sm:after:h-12 after:w-16 sm:after:w-28 after:text-main after:bg-clrMainDark after:flex after:justify-center after:items-center after:text-clrMainLight after:font-normal after:transition-transform after:duration-300 after:ease-in-out after:scale-0 peer-checked:after:bg-black peer-checked:after:content-['Viber'] peer-checked:after:transform peer-checked:after:scale-100"></div>
                                          </label>
                                      </div>
                                  </div>
                              </div>
                          </div>
            

                          <div class="flex">
                              <div class="flex items-center">
                                  <input id="accept_personal_data_terms" type="checkbox" required v-model="form.accept_personal_data_terms" class="w-3 h-3 border-0 text-clrMainDark order-clrMainDark p-2.5 rounded-full outline outline-[1.5px] outline-offset-2 outline-clrMainDark focus:outline focus:outline-[1.5px] focus:outline-offset-2 focus:outline-clrMainDark focus:text-clrMainDark focus:outline-none focus:ring-0 focus:ring-clrMainDark" >
                                  <label for="accept_personal_data_terms" class="ms-4 text-main text-clrMainDark font-medium">Принимаю условия обработки персональных данных</label>
                              </div>

                              <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Отправить данные</button>

                          </div>   
                      </form>
                      
                      <div v-if="errors.non_field_errors">
                          <span>{{ errors.non_field_errors[0] }}</span>
                      </div>      

                </div>
              
                <div v-else>
                      Данные были отправлены. <br>
                      Мы свяжемся с вами в ближайшее время.
                </div>
              </div>



          </div>
      </div>








</template>

<script>
  import axios from 'axios';


  export default {
    name: 'App',

    data() {
        return {
            isNotFormSubmited: true,
            message: 'Hello, Vue 3!',
            form: {
                name: '',
                phone_number: '+375',
                contact_by_phone: true,
                contact_by_telegram: false,
                contact_by_whatsapp: false,
                contact_by_viber: false,
                accept_personal_data_terms: false,
            },
            errors: {},
            last_phone_number: '',

            err: '',
            errdata: '',
        };
    },



    methods: {
      async submitForm() {
        try {
            const csrftoken = this.getCookie('csrftoken'); // Получаем CSRF-токен из куки
            const response = await axios.post('/api/form/', this.form, {
                headers: {
                    'X-CSRFToken': csrftoken // Добавляем CSRF-токен в заголовок
                }
            });
            this.isNotFormSubmited = false;
            this.errors = {};
            console.log(response.data);
        } catch (error) {
            if (error.response && error.response.data) {
                this.errors = error.response.data;
            } else {
                console.error('Error creating category:', error);
            }
        }
      },
      getCookie(name) {
          let cookieValue = null;
          if (document.cookie && document.cookie !== '') {
              const cookies = document.cookie.split(';');
              for (let i = 0; i < cookies.length; i++) {
                  const cookie = cookies[i].trim();
                  if (cookie.substring(0, name.length + 1) === (name + '=')) {
                      cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                      break;
                  }
              }
          }
          return cookieValue;
      },
      formatPhoneNumber() {
          // Удаляем все символы, кроме цифр
          var cleanedValue = this.form.phone_number.replace(/\D/g, "");

          // Применяем формат +375 (XX) XXX-XX-XX
          var formattedValue = "+375";
          if (cleanedValue.length > 3) {
              formattedValue += " (" + cleanedValue.substr(3, 2) + "";
          }
          if (cleanedValue.length > 4) {
              formattedValue += ") " + cleanedValue.substr(5, 3);
          }
          if(this.last_phone_number.length === 10 && formattedValue.length === 10)
          {                
              formattedValue = formattedValue.substring(0, 8);
              this.form.phone_number = formattedValue;
              return
          }
          if (cleanedValue.length > 8) {
              formattedValue += "-" + cleanedValue.substr(8, 2);
          }
          if (cleanedValue.length > 10) {
              formattedValue += "-" + cleanedValue.substr(10, 2);
          }

          this.form.phone_number = formattedValue;
          this.last_phone_number = formattedValue
      },

    
    } 
  }
</script>




      
<style scoped>
  form span {
    display: block;
    /* margin-top: 5px; */
    color: red;
    /* font-size: 0.9em; */
  }
</style>




